body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiTableCell-root {
  display: table-cell;
  padding: 9px !important;
  font-size: 0.875rem;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}



/* @keyframes ldio-twu1mcv17u {
  0%, 100% {
    animation-timing-function: cubic-bezier(0.45,0,0.9,0.55)
  }
  0% {
    transform: translate(0,0)
  }
  50% {
    transform: translate(0,70.74000000000001px);
    animation-timing-function: cubic-bezier(0,0.45,0.55,0.9);
  }
  100% {
    transform: translate(0,0);
  }
}

.ldio-twu1mcv17u div {
  position: absolute;
  width: 34.06px;
  height: 34.06px;
  border-radius: 50%;
  background: #26d6d6;
  left: 48.47px;
  top: 13.100000000000001px;
  animation: ldio-twu1mcv17u 1s linear infinite;
}
.loadingio-spinner-ball-fql8hmvasdh {
  width: 131px;
  height: 131px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-twu1mcv17u {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-twu1mcv17u div { box-sizing: content-box; } */


@keyframes ldio-vl3gedm00so {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1.5,1.5);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-vl3gedm00so div > div {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #68c5bd;
  animation: ldio-vl3gedm00so 1.0204081632653061s linear infinite;
}.ldio-vl3gedm00so div:nth-child(1) > div {
  left: 148px;
  top: 88px;
  animation-delay: -0.8928571428571429s;
}
.ldio-vl3gedm00so > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 160px 100px;
}.ldio-vl3gedm00so div:nth-child(2) > div {
  left: 130px;
  top: 130px;
  animation-delay: -0.7653061224489796s;
}
.ldio-vl3gedm00so > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 142px 142px;
}.ldio-vl3gedm00so div:nth-child(3) > div {
  left: 88px;
  top: 148px;
  animation-delay: -0.6377551020408163s;
}
.ldio-vl3gedm00so > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 100px 160px;
}.ldio-vl3gedm00so div:nth-child(4) > div {
  left: 46px;
  top: 130px;
  animation-delay: -0.5102040816326531s;
}
.ldio-vl3gedm00so > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 58px 142px;
}.ldio-vl3gedm00so div:nth-child(5) > div {
  left: 28px;
  top: 88px;
  animation-delay: -0.3826530612244898s;
}
.ldio-vl3gedm00so > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 40px 100px;
}.ldio-vl3gedm00so div:nth-child(6) > div {
  left: 46px;
  top: 46px;
  animation-delay: -0.25510204081632654s;
}
.ldio-vl3gedm00so > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 58px 58px;
}.ldio-vl3gedm00so div:nth-child(7) > div {
  left: 88px;
  top: 28px;
  animation-delay: -0.12755102040816327s;
}
.ldio-vl3gedm00so > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 100px 40px;
}.ldio-vl3gedm00so div:nth-child(8) > div {
  left: 130px;
  top: 46px;
  animation-delay: 0s;
}
.ldio-vl3gedm00so > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 142px 58px;
}
.loadingio-spinner-spin-ihraavdfyi {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-vl3gedm00so {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-vl3gedm00so div { box-sizing: content-box; }

/* generated by https://loading.io/ */



/* generated by https://loading.io/ */

/* .MuiTableContainer-root {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto!important;
  white-space: nowrap !important;
} */
