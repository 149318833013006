.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.MuiOutlinedInput-inputMarginDense {
  padding-top: 7.5px !important;
  padding-bottom: 7.5px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
  padding: 0px !important;
}

.pointer {
  width: 50px;
  height: 34px;
  position: relative;
  background: #555;
  border-width: 1px;
}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-left: 19px solid #3b8a8f;
  border-bottom: 17px solid transparent;
}

.timeline-parent {
  /* background-color: #13676a; */
  width: 40%;
  display: flex;
  border-bottom: 54px solid #13676a;
  border-right: 25px solid transparent;
}

.timeline-down-backround {
  background-color: #cadff4;
  width: 80%;
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 45px;
}
.timeline-down-backround:before {
  content: "";
  position: absolute;
  border: 23px solid transparent;
  border-bottom-color: #cadff4;
  border-right-color: #cadff4;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.timeline-container {
  background-color: #273c75;
  width: 120px;
  position: absolute;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.timeline-container:before {
  content: "";
  position: absolute;
  border: 22.5px solid transparent;
  border-bottom-color: #273c75;
  border-right-color: #273c75;
  right: 100%;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.timeline-container:after {
  content: "";
  position: absolute;
  border: 22.5px solid transparent;
  border-top-color: #273c75;
  border-left-color: #273c75;
  left: 100%;
  top: 0;
  width: 0;
  height: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

element.style {
  order: unset;
}
.drawerValue {
  background-color: red !important;
}

/* .MuiSelect-selectMenu {
  height:auto;
  min-height: 0.6em !important;
}
.MuiOutlinedInput-inputMarginDense {
  padding-top: 7.5px!important;
  padding-bottom: 7.5px!important;
} */
