.newDesign .lb-container {

    top: 49px!important;
    background:transparent!important;
  
  }
.newDesign .lb-canvas{
    background:rgb(116, 116, 116)
}
.newDesign .lb-icon-close {
    background-image:none!important;
}
  